export type Languages = "en" | "es" | "de" | "it" | "fr" | "pt" | "zh";
type Translations = {
  [K in Languages]: string;
}
type GeneralTranslations = {
  nativeName: Translations;
  language: Translations;
}

type GreetingTranslations = {
  hello: Translations;
  welcome: Translations;
  enterName: Translations;
};

type HeadlineTranslations = {
  rooms: Translations;
};
type ButtonTranslations = {
  join: Translations;
  save: Translations;
  cancel: Translations;
  leave: Translations;
  checkAudioDevices: Translations;
  mute: Translations;
  unmute: Translations;
  back: Translations;
};
type TextTranslations = {
  creator: Translations;
  roomName: Translations;
  roomDescription: Translations;
  roomCreatedBy: Translations;
  roomCreatedAt: Translations;
  createRoom: Translations;
  languageRoom: Translations;
  joinRoom: Translations;
  joinLanguageRoom: Translations;
  room: Translations;
  roomSettings: Translations;
  roomMembers: Translations;
  roomMessages: Translations;
  loading: Translations;
  nobodyHere: Translations;
  you: Translations;
  selectYourDesiredAudioDevices: Translations;
  makeSureYouHaveTheCorrectAudioDevicesSelected: Translations;
  makeSureYouHaveAllowedMicrophoneAccess: Translations;
  microphone: Translations;
  speakers: Translations;
  difficulty: Translations;
  participants: Translations;
  audioSettings: Translations;
};

type DifficultyTranslations = {
  Beginner: Translations;
  Intermediate: Translations;
  Advanced: Translations;
  whoIsThisLevelFor: Translations;
  descriptions: DifficultyDescriptionTranslations;
}

type DifficultyDescriptionTranslations = {
  Beginner: Translations;
  Intermediate: Translations;
  Advanced: Translations;
}

type ErrorTranslations = {
  error: Translations;
  localStreamNotFound: Translations;
}

type AllTranslations = {
  general: GeneralTranslations;
  greetings: GreetingTranslations;
  headlines: HeadlineTranslations;
  buttons: ButtonTranslations;
  texts: TextTranslations;
  errors: ErrorTranslations;
  difficulties: DifficultyTranslations;
};

export const translations: AllTranslations = {
  general: {
    nativeName: {
      de: "Deutsch",
      es: "Español",
      en: "English",
      zh: "中文",
      fr: "Français",
      it: "Italiano",
      pt: "Português",
    },
    language: {
      de: "Sprache",
      es: "Idioma",
      en: "Language",
      zh: "语言",
      pt: "Língua",
      it: "Lingua",
      fr: "Langue",
    },
  },
  greetings: {
    hello: {
      de: "Hallo",
      es: "Hola",
      en: "Hello",
      zh: "你好",
      it: "Ciao",
      fr: "Bonjour",
      pt: "Olá",
    },
    welcome: {
      de: "Willkommen",
      es: "Bienvenido",
      en: "Welcome",
      zh: "欢迎",
      it: "Benvenuto",
      fr: "Bienvenue",
      pt: "Bem-vindo",
    },
    enterName: {
      de: "Gib deinen Namen ein",
      es: "Ingresa tu nombre",
      en: "Enter your name",
      zh: "输入你的名字",
      it: "Inserisci il tuo nome",
      fr: "Entrez votre nom",
      pt: "Digite seu nome",
    },
  },
  difficulties: {
    Beginner: {
      de: "Anfänger",
      es: "Principiante",
      en: "Beginner",
      zh: "初学者",
      it: "Principiante",
      fr: "Débutant",
      pt: "Iniciante",
    },
    Intermediate: {
      de: "Mittelstufe",
      es: "Intermedio",
      en: "Intermediate",
      zh: "中级",
      it: "Intermedio",
      fr: "Intermédiaire",
      pt: "Intermediário",
    },
    Advanced: {
      de: "Fortgeschritten",
      es: "Avanzado",
      en: "Advanced",
      zh: "高级",
      it: "Avanzato",
      fr: "Avancé",
      pt: "Avançado",
    },
    whoIsThisLevelFor: {
      de: "Für wen ist dieser Schwierigkeitsgrad?",
      es: "¿Para quién es este nivel?",
      en: "Who is this level for?",
      zh: "这个级别适合谁？",
      it: "A chi è rivolto questo livello?",
      fr: "Pour qui est ce niveau?",
      pt: "Para quem é este nível?",
    },
    descriptions: {
      Beginner: {
        en: "Beginner rooms are ideal for those at the start of their language learning journey. If you're new to the language, have limited vocabulary, or are looking to practice basic phrases and simple conversations, this is the perfect level for you. These rooms provide a supportive environment to build your confidence in speaking and understanding fundamental language concepts. You'll focus on essential grammar, everyday vocabulary, and basic sentence structures.",
        de: "Anfängerräume sind ideal für diejenigen, die am Anfang ihrer Sprachlernreise stehen. Wenn Sie neu in der Sprache sind, einen begrenzten Wortschatz haben oder grundlegende Phrasen und einfache Gespräche üben möchten, ist dies der perfekte Level für Sie. Diese Räume bieten eine unterstützende Umgebung, um Ihr Selbstvertrauen im Sprechen und Verstehen grundlegender Sprachkonzepte aufzubauen. Sie werden sich auf die wesentliche Grammatik, den alltäglichen Wortschatz und einfache Satzstrukturen konzentrieren.",
        es: "Las salas para principiantes son ideales para aquellos que están al comienzo de su viaje de aprendizaje de idiomas. Si eres nuevo en el idioma, tienes un vocabulario limitado o buscas practicar frases básicas y conversaciones simples, este es el nivel perfecto para ti. Estas salas proporcionan un entorno de apoyo para construir tu confianza al hablar y comprender conceptos de idiomas fundamentales. Te enfocarás en la gramática esencial, el vocabulario cotidiano y las estructuras de oraciones básicas.",
        zh: "初学者房间非常适合那些刚开始学习语言的人。如果您是第一次接触这种语言，词汇量有限，或者想练习基本短语和简单对话，那么这个级别非常适合您。这些房间提供了一个支持性的环境，可以帮助您建立对说话和理解基本语言概念的信心。您将专注于基本语法、日常词汇和基本句子结构。",
        it: "Le stanze per principianti sono ideali per coloro che sono all'inizio del loro percorso di apprendimento della lingua. Se sei nuovo nella lingua, hai un vocabolario limitato o desideri praticare frasi di base e conversazioni semplici, questo è il livello perfetto per te. Queste stanze forniscono un ambiente di supporto per costruire la tua fiducia nel parlare e comprendere concetti linguistici fondamentali. Ti concentrerai sulla grammatica essenziale, il vocabolario quotidiano e le strutture di base delle frasi.",
        fr: "Les salles pour débutants sont idéales pour ceux qui débutent leur apprentissage d'une langue. Si vous êtes novice dans la langue, avez un vocabulaire limité ou souhaitez pratiquer des phrases de base et des conversations simples, ce niveau est parfait pour vous. Ces salles offrent un environnement de soutien pour renforcer votre confiance à parler et à comprendre les concepts linguistiques fondamentaux. Vous vous concentrerez sur la grammaire essentielle, le vocabulaire quotidien et les structures de phrases de base.",
        pt: "As salas para iniciantes são ideais para aqueles que estão no início de sua jornada de aprendizado de idiomas. Se você é novo no idioma, tem vocabulário limitado ou deseja praticar frases básicas e conversas simples, este é o nível perfeito para você. Essas salas oferecem um ambiente de apoio para construir sua confiança em falar e entender conceitos linguísticos fundamentais. Você se concentrará em gramática essencial, vocabulário cotidiano e estruturas de frases básicas.",
      },
      Intermediate: {
        en: "Intermediate rooms are designed for learners who have a good grasp of the basics and are ready to take their skills to the next level. If you can hold simple conversations, understand common phrases, and want to improve your fluency and expand your vocabulary, this level is for you. In these rooms, you'll engage in more complex discussions, explore nuances of the language, and start to understand idiomatic expressions. You'll work on refining your grammar, broadening your vocabulary, and improving your ability to express more complex ideas.",
        de: "Mittelstufenräume sind für Lernende konzipiert, die die Grundlagen gut beherrschen und bereit sind, ihre Fähigkeiten auf die nächste Stufe zu bringen. Wenn Sie einfache Gespräche führen können, gebräuchliche Phrasen verstehen und Ihre Sprachgewandtheit verbessern und Ihren Wortschatz erweitern möchten, ist dieser Level für Sie. In diesen Räumen werden Sie sich an komplexeren Diskussionen beteiligen, die Feinheiten der Sprache erkunden und anfangen, idiomatische Ausdrücke zu verstehen. Sie werden an der Verfeinerung Ihrer Grammatik arbeiten, Ihren Wortschatz erweitern und Ihre Fähigkeit verbessern, komplexere Ideen auszudrücken.",
        es: "Las salas intermedias están diseñadas para estudiantes que tienen un buen dominio de los conceptos básicos y están listos para llevar sus habilidades al siguiente nivel. Si puedes mantener conversaciones simples, entender frases comunes y deseas mejorar tu fluidez y ampliar tu vocabulario, este nivel es para ti. En estas salas, participarás en discusiones más complejas, explorarás matices del idioma y comenzarás a entender expresiones idiomáticas. Trabajarás en perfeccionar tu gramática, ampliar tu vocabulario y mejorar tu capacidad para expresar ideas más complejas.",
        zh: "中级房间适用于掌握基础知识并准备将技能提升到下一个水平的学习者。如果您可以进行简单的对话，理解常见短语，并希望提高流利度并扩展词汇量，那么这个级别适合您。在这些房间中，您将参与更复杂的讨论，探索语言的细微差别，并开始理解习语表达。您将致力于完善语法，扩大词汇量，并提高表达更复杂思想的能力。",
        it: "Le stanze intermedie sono progettate per gli studenti che hanno una buona padronanza delle basi e sono pronti a portare le loro competenze al livello successivo. Se sai sostenere conversazioni semplici, comprendere frasi comuni e desideri migliorare la tua fluidità e ampliare il tuo vocabolario, questo livello è per te. In queste stanze, parteciperai a discussioni più complesse, esplorerai sfumature della lingua e inizierai a comprendere espressioni idiomatiche. Lavorerai per perfezionare la tua grammatica, ampliare il tuo vocabolario e migliorare la tua capacità di esprimere idee più complesse.",
        fr: "Les salles intermédiaires sont conçues pour les apprenants qui maîtrisent bien les bases et sont prêts à faire évoluer leurs compétences vers le niveau supérieur. Si vous pouvez tenir des conversations simples, comprendre des phrases courantes et souhaitez améliorer votre fluidité et élargir votre vocabulaire, ce niveau est fait pour vous. Dans ces salles, vous participerez à des discussions plus complexes, explorerez les nuances de la langue et commencerez à comprendre les expressions idiomatiques. Vous travaillerez à affiner votre grammaire, à élargir votre vocabulaire et à améliorer votre capacité à exprimer des idées plus complexes.",
        pt: "As salas intermediárias são projetadas para alunos que têm um bom domínio dos conceitos básicos e estão prontos para levar suas habilidades ao próximo nível. Se você pode manter conversas simples, entender frases comuns e deseja melhorar sua fluência e expandir seu vocabulário, este nível é para você. Nestas salas, você participará de discussões mais complexas, explorará nuances da língua e começará a entender expressões idiomáticas. Você trabalhará para aprimorar sua gramática, ampliar seu vocabulário e melhorar sua capacidade de expressar ideias mais complexas.",
      },
      Advanced: {
        en: "Advanced rooms are tailored for learners who are highly proficient and are looking to achieve near-native fluency. If you can confidently discuss complex topics, understand nuanced expressions, and want to refine your language skills to a professional level, this is the right choice for you. In these rooms, you'll engage in sophisticated discussions on literature, culture, and current events. You'll work with advanced vocabulary, idiomatic expressions, and subtle language nuances. These sessions are designed to challenge you and push your language skills to their limits, helping you achieve mastery in all aspects of the language.",
        de: "Fortgeschrittenenräume sind für Lernende konzipiert, die sehr versiert sind und eine nahezu muttersprachliche Sprachbeherrschung anstreben. Wenn Sie komplexe Themen selbstbewust diskutieren können, nuancierte Ausdrücke verstehen und Ihre Sprachkenntnisse auf professionellem Niveau verfeinern möchten, ist dies die richtige Wahl für Sie. In diesen Räumen werden Sie sich an anspruchsvollen Diskussionen über Literatur, Kultur und aktuelle Ereignisse beteiligen. Sie werden mit fortgeschrittenem Vokabular, idiomatischen Ausdrücken und subtilen Sprachnuancen arbeiten. Diese Sitzungen sind darauf ausgelegt, Sie herauszufordern und Ihre Sprachkenntnisse an ihre Grenzen zu bringen, um Ihnen zu helfen, in allen Aspekten der Sprache Meisterschaft zu erlangen.",
        es: "Las salas avanzadas están diseñadas para estudiantes que son altamente competentes y buscan alcanzar una fluidez casi nativa. Si puedes discutir con confianza temas complejos, entender expresiones matizadas y deseas perfeccionar tus habilidades lingüísticas a un nivel profesional, esta es la elección correcta para ti. En estas salas, participarás en discusiones sofisticadas sobre literatura, cultura y eventos actuales. Trabajarás con vocabulario avanzado, expresiones idiomáticas y matices sutiles del lenguaje. Estas sesiones están diseñadas para desafiarte y llevar tus habilidades lingüísticas a sus límites, ayudándote a alcanzar la maestría en todos los aspectos del idioma.",
        zh: "高级房间专为高水平学习者量身定制，旨在实现接近母语流利度。如果您可以自信地讨论复杂话题，理解微妙的表达方式，并希望将语言技能提炼到专业水平，那么这是您的正确选择。在这些房间中，您将参与有关文学、文化和时事的复杂讨论。您将使用高级词汇、习语表达和微妙的语言细微差别。这些会话旨在挑战您，将您的语言技能推向极限，帮助您在语言的所有方面都达到精通。",
        it: "Le stanze avanzate sono progettate per gli studenti che sono altamente competenti e desiderano raggiungere una fluidità quasi nativa. Se sai discutere con sicurezza argomenti complessi, comprendere espressioni sfumate e desideri perfezionare le tue abilità linguistiche a livello professionale, questa è la scelta giusta per te. In queste stanze, parteciperai a discussioni sofisticate su letteratura, cultura ed eventi attuali. Lavorerai con vocabolario avanzato, espressioni idiomatiche e sfumature linguistiche sottili. Queste sessioni sono progettate per sfidarti e spingere le tue abilità linguistiche ai loro limiti, aiutandoti a raggiungere la padronanza in tutti gli aspetti della lingua.",
        fr: "Les salles avancées sont conçues pour les apprenants qui sont très compétents et cherchent à atteindre une fluidité quasi native. Si vous pouvez discuter de sujets complexes en toute confiance, comprendre des expressions nuancées et souhaitez affiner vos compétences linguistiques à un niveau professionnel, c'est le bon choix pour vous. Dans ces salles, vous participerez à des discussions sophistiquées sur la littérature, la culture et les événements actuels. Vous travaillerez avec un vocabulaire avancé, des expressions idiomatiques et des nuances subtiles du langage. Ces séances sont conçues pour vous défier et pousser vos compétences linguistiques à leurs limites, vous aidant à atteindre la maîtrise dans tous les aspects de la langue.",
        pt: "As salas avançadas são projetadas para alunos altamente competentes que buscam alcançar fluência quase nativa. Se você pode discutir com confiança tópicos complexos, entender expressões matizadas e deseja aprimorar suas habilidades linguísticas a um nível profissional, esta é a escolha certa para você. Nestas salas, você participará de discussões sofisticadas sobre literatura, cultura e eventos atuais. Você trabalhará com vocabulário avançado, expressões idiomáticas e nuances sutis da linguagem. Essas sessões são projetadas para desafiá-lo e levar suas habilidades linguísticas aos seus limites, ajudando-o a alcançar a maestria em todos os aspectos da língua.",
      },
    },

  },
  headlines: {
    rooms: {
      de: "Räume",
      es: "Salas",
      en: "Rooms",
      zh: "房间",
      it: "Stanze",
      fr: "Salles",
      pt: "Salas",
    },
  },
  buttons: {
    join: {
      de: "Beitreten",
      es: "Unirse",
      en: "Join",
      zh: "加入",
      it: "Unisciti",
      fr: "Joindre",
      pt: "Junte-se",
    },
    save: {
      de: "Speichern",
      es: "Guardar",
      en: "Save",
      zh: "保存",
      it: "Salva",
      fr: "Sauvegarder",
      pt: "Salvar",
    },
    cancel: {
      de: "Abbrechen",
      es: "Cancelar",
      en: "Cancel",
      zh: "取消",
      it: "Annulla",
      fr: "Annuler",
      pt: "Cancelar",
    },
    leave: {
      de: "Verlassen",
      es: "Salir",
      en: "Leave",
      zh: "离开",
      it: "Lascia",
      fr: "Quitter",
      pt: "Sair",
    },
    checkAudioDevices: {
      de: "Audio-Geräte überprüfen",
      es: "Comprobar Dispositivos de Audio",
      en: "Check Audio Devices",
      zh: "检查音频设备",
      it: "Controlla i Dispositivi Audio",
      fr: "Vérifier les Périphériques Audio",
      pt: "Verificar Dispositivos de Áudio",
    },
    mute: {
      de: "Stummschalten",
      es: "Silenciar",
      en: "Mute",
      zh: "静音",
      it: "Muto",
      fr: "Muet",
      pt: "Mudo",
    },
    unmute: {
      de: "Stummschaltung aufheben",
      es: "Quitar Silencio",
      en: "Unmute",
      zh: "取消静音",
      it: "Non Muto",
      fr: "Désactiver le Muet",
      pt: "Desmutar",
    },
    back: {
      de: "Zurück",
      es: "Atrás",
      en: "Back",
      zh: "返回",
      it: "Indietro",
      fr: "Retour",
      pt: "Voltar",
    },
  },
  texts: {
    creator: {
      de: "Ersteller",
      es: "Creador",
      en: "Creator",
      zh: "创建者",
      it: "Creatore",
      fr: "Créateur",
      pt: "Criador",
    },
    participants: {
      de: "Teilnehmer",
      es: "Participantes",
      en: "Participants",
      zh: "参与者",
      it: "Partecipanti",
      fr: "Participants",
      pt: "Participantes",
    },
    roomName: {
      de: "Raumname",
      es: "Nombre de la Sala",
      en: "Room Name",
      zh: "房间名称",
      it: "Nome della Stanza",
      fr: "Nom de la Salle",
      pt: "Nome da Sala",
    },
    roomDescription: {
      de: "Raumbeschreibung",
      es: "Descripción de la Sala",
      en: "Room Description",
      zh: "房间描述",
      it: "Descrizione della Stanza",
      fr: "Description de la Salle",
      pt: "Descrição da Sala",
    },
    roomCreatedBy: {
      de: "Raum erstellt von",
      es: "Sala Creada Por",
      en: "Room Created By",
      zh: "房间创建者",
      it: "Stanza creata da",
      fr: "Salle créée par",
      pt: "Sala Criada Por",
    },
    roomCreatedAt: {
      de: "Raum erstellt am",
      es: "Sala Creada En",
      en: "Room Created At",
      zh: "房间创建于",
      it: "Stanza creata il",
      fr: "Salle créée le",
      pt: "Sala Criada Em",
    },
    createRoom: {
      de: "Raum erstellen",
      es: "Crear Sala",
      en: "Create Room",
      zh: "创建房间",
      it: "Crea Stanza",
      fr: "Créer une Salle",
      pt: "Criar Sala",
    },
    joinRoom: {
      de: "Raum beitreten",
      es: "Unirse a la Sala",
      en: "Join Room",
      zh: "加入房间",
      it: "Unisciti alla Stanza",
      fr: "Rejoindre une Salle",
      pt: "Junte-se à Sala",
    },
    joinLanguageRoom: {
      en: "Join language room",
      de: "Sprachraum beitreten",
      es: "Unirse a la Sala de Idioma",
      zh: "加入语言房间",
      it: "Unisciti alla Stanza della Lingua",
      fr: "Rejoindre la Salle de Langue",
      pt: "Junte-se à Sala de Idiomas",
    },
    languageRoom: {
      de: "Sprachraum",
      es: "Sala de Idioma",
      en: "Language Room",
      zh: "语言房间",
      it: "Stanza della Lingua",
      fr: "Salle de Langue",
      pt: "Sala de Idiomas",
    },
    room: {
      de: "Raum",
      es: "Sala",
      en: "Room",
      zh: "房间",
      it: "Stanza",
      fr: "Salle",
      pt: "Sala",
    },
    roomSettings: {
      de: "Raumeinstellungen",
      es: "Configuración de la Sala",
      en: "Room Settings",
      zh: "房间设置",
      it: "Impostazioni della Stanza",
      fr: "Paramètres de la Salle",
      pt: "Configurações da Sala",
    },
    roomMembers: {
      de: "Raummitglieder",
      es: "Miembros de la Sala",
      en: "Room Members",
      zh: "房间成员",
      it: "Membri della Stanza",
      fr: "Membres de la Salle",
      pt: "Membros da Sala",
    },
    roomMessages: {
      de: "Raumnachrichten",
      es: "Mensajes de la Sala",
      en: "Room Messages",
      zh: "房间消息",
      it: "Messaggi della Stanza",
      fr: "Messages de la Salle",
      pt: "Mensagens da Sala",

    },
    loading: {
      de: "Laden...",
      es: "Cargando...",
      en: "Loading...",
      zh: "载入中...",
      it: "Caricamento in corso...",
      fr: "Chargement...",
      pt: "Carregando...",
    },
    nobodyHere: {
      de: "Niemand hier",
      es: "Nadie Aquí",
      en: "Nobody Here",
      zh: "这里没有人",
      it: "Nessuno qui",
      fr: "Personne ici",
      pt: "Ninguém Aqui",
    },
    you: {
      de: "Du",
      es: "Tú",
      en: "You",
      zh: "你",
      it: "Tu",
      fr: "Tu",
      pt: "Tu",
    },
    selectYourDesiredAudioDevices: {
      de: "Wähle deine gewünschten Audio-Geräte",
      es: "Selecciona tus dispositivos de audio deseados",
      en: "Select your desired audio devices",
      zh: "选择您想要的音频设备",
      it: "Seleziona i tuoi dispositivi audio desiderati",
      fr: "Sélectionnez vos périphériques audio souhaités",
      pt: "Selecione seus dispositivos de áudio desejados",
    },
    makeSureYouHaveTheCorrectAudioDevicesSelected: {
      de: "Stelle sicher, dass du die richtigen Audio-Geräte ausgewählt hast",
      es: "Asegúrate de tener seleccionados los dispositivos de audio correctos",
      en: "Make sure you have the correct audio devices selected",
      zh: "确保您选择了正确的音频设备",
      it: "Assicurati di aver selezionato i dispositivi audio corretti",
      fr: "Assurez-vous d'avoir sélectionné les bons périphériques audio",
      pt: "Certifique-se de ter selecionado os dispositivos de áudio corretos",
    },
    makeSureYouHaveAllowedMicrophoneAccess: {
      de: "Stelle sicher, dass du dem Browser erlaubt hast, auf dein Mikrofon zuzugreifen",
      es: "Asegúrate de haber permitido al navegador acceder a tu micrófono",
      en: "Make sure you have allowed the browser to access your microphone",
      zh: "确保您允许浏览器访问您的麦克风",
      it: "Assicurati di aver permesso al browser di accedere al tuo microfone",
      fr: "Assurez-vous d'avoir autorisé le navigateur à accéder à votre microphone",
      pt: "Certifique-se de ter permitido que o navegador acesse seu microfone",
    },
    microphone: {
      de: "Mikrofon",
      es: "Micrófono",
      en: "Microphone",
      zh: "麦克风",
      it: "Microfono",
      fr: "Microphone",
      pt: "Microfone",
    },
    speakers: {
      de: "Lautsprecher",
      es: "Altavoces",
      en: "Speakers",
      zh: "扬声器",
      it: "Altoparlanti",
      fr: "Haut-parleurs",
      pt: "Alto-falantes",
    },
    difficulty: {
      de: "Schwierigkeit",
      es: "Dificultad",
      en: "Difficulty",
      zh: "难度",
      it: "Difficoltà",
      fr: "Difficulté",
      pt: "Dificuldade",
    },
    audioSettings: {
      de: "Audio-Einstellungen",
      es: "Configuración de Audio",
      en: "Audio Settings",
      zh: "音频设置",
      it: "Impostazioni Audio",
      fr: "Paramètres Audio",
      pt: "Configurações de Áudio",
    },
  },
  errors: {
    error: {
      de: "Fehler",
      es: "Error",
      en: "Error",
      zh: "错误",
      it: "Errore",
      fr: "Erreur",
      pt: "Erro",
    },
    localStreamNotFound: {
      en: "Local stream not found. Make sure that you allow the browser to access your camera and microphone.",
      de: "Lokaler Stream nicht gefunden. Stellen Sie sicher, dass Sie dem Browser erlauben, auf Ihre Kamera und Ihr Mikrofon zuzugreifen.",
      es: "No se encontró el flujo local. Asegúrate de permitir que el navegador acceda a tu cámara y micrófono.",
      zh: "未找到本地流。确保您允许浏览器访问摄像头和麦克风。",
      it: "Flusso locale non trovato. Assicurati di consentire al browser di accedere alla tua fotocamera e al tuo microfono.",
      fr: "Flux local non trouvé. Assurez-vous de permettre au navigateur d'accéder à votre caméra et à votre microphone.",
      pt: "Fluxo local não encontrado. Certifique-se de permitir que o navegador acesse sua câmera e microfone.",
    }
  }
}