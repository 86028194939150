<template>
  <footer
    class="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700"
  >
    <div class="container mx-auto px-6 py-8">
      <div class="flex justify-center items-center">
        <p class="text-gray-600 dark:text-gray-400">
          &copy; {{ new Date().getFullYear() }} Language Rooms. All rights
          reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
