import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  connectAuthEmulator,
  initializeAuth,
} from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { Analytics, getAnalytics } from 'firebase/analytics';

const localhost = '127.0.0.1';

const firebaseApp = initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
});

const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: [browserLocalPersistence],
  popupRedirectResolver: browserPopupRedirectResolver,
});
const db = getDatabase(firebaseApp);

let analytics: Analytics | null = null;

if (process.env.NODE_ENV === 'development') {
  connectDatabaseEmulator(db, localhost, 9000);
  connectAuthEmulator(firebaseAuth, `http://${localhost}:9099`);
  console.log('connected to realtime database emulator');
} else {
  analytics = getAnalytics(firebaseApp);
}

export const getPeersRefByRoomId = (roomId: string) => `rooms/${roomId}/peers`;
export const roomsRef = 'rooms/';
export const getRoomsRef = (uid: string) => `rooms/${uid}`;

export const waitlistRef = 'waitlist/';

export { firebaseApp, firebaseAuth, db, analytics };
