// composables/useLanguage.ts
import { ref } from 'vue';
import { translations } from '../i18n/translations';
import { Languages } from '../i18n/translations';

export const availableLanguages: Record<Languages, string> = Object.keys(translations.general.nativeName).reduce((acc, lang) => {
  acc[lang as Languages] = translations.general.nativeName[lang as Languages];
  return acc;
}, {} as Record<Languages, string>);

const currentLanguage = ref<Languages>("en"); // Default language

export function useLanguage() {
  function setLanguage(lang: Languages) {
    currentLanguage.value = lang;
  }

  return { currentLanguage, setLanguage, availableLanguages };
}

export const difficultyColors = {
  Beginner: {
    border: 'border-green-500 dark:border-green-700',
    bg: 'bg-green-100 dark:bg-green-900',
    text: 'text-green-800 dark:text-green-100',
    header: 'bg-green-50 dark:bg-green-900',
    headerBorder: 'border-green-100 dark:border-green-800',
    icon: 'text-green-600 dark:text-green-400',
    button: 'bg-green-500 hover:bg-green-600'
  },
  Intermediate: {
    border: 'border-yellow-500 dark:border-yellow-700',
    bg: 'bg-yellow-100 dark:bg-yellow-900',
    text: 'text-yellow-800 dark:text-yellow-100',
    header: 'bg-yellow-50 dark:bg-yellow-900',
    headerBorder: 'border-yellow-100 dark:border-yellow-800',
    icon: 'text-yellow-600 dark:text-yellow-400',
    button: 'bg-yellow-500 hover:bg-yellow-600'
  },
  Advanced: {
    border: 'border-red-500 dark:border-red-700',
    bg: 'bg-red-100 dark:bg-red-900',
    text: 'text-red-800 dark:text-red-100',
    header: 'bg-red-50 dark:bg-red-900',
    headerBorder: 'border-red-100 dark:border-red-800',
    icon: 'text-red-600 dark:text-red-400',
    button: 'bg-red-500 hover:bg-red-600'
  }
};