<script setup lang="ts">
import {
  Globe2,
  MessageCircle,
  Users,
  Brain,
  Clock,
  Zap,
  Rocket,
  Mail,
  Star,
  Lock,
  CheckCircle,
  MessageSquare,
} from 'lucide-vue-next';

import { ref } from 'vue';
import { db, waitlistRef } from '../../firebase';
import { push, ref as dbRef, set, get } from 'firebase/database';

const journeySteps = ref([
  { icon: MessageCircle, text: 'Start Speaking' },
  { icon: Brain, text: 'Build Vocabulary' },
  { icon: Globe2, text: 'Cultural Immersion' },
  { icon: Users, text: 'Achieve Fluency' },
]);

const earlyAccessEmail = ref('');
const earlyAccessRequested = ref(false);

async function submitEarlyAccess() {
  // check if email is already in the waitlist
  const waitlistSnapshot = await get(dbRef(db, waitlistRef));
  const waitlistEmails = Object.values(waitlistSnapshot.val() ?? {});
  const emailExists = waitlistEmails.includes(earlyAccessEmail.value);

  if (emailExists) {
    earlyAccessEmail.value = 'You are already on the waitlist!';
    earlyAccessRequested.value = true;
    return;
  }

  try {
    // add new doc with firebase and automatic ID
    const newWaitlistRef = push(dbRef(db, waitlistRef));

    await set(newWaitlistRef, earlyAccessEmail.value);
  } catch (error: unknown) {
    console.error(error);
  }

  earlyAccessEmail.value = '';
  earlyAccessRequested.value = true;
}
</script>

<template>
  <div class="flex flex-col items-center">
    <!-- Hero Section -->
    <section
      class="w-full py-12 md:py-24 lg:py-32 xl:py-48 flex bg-gradient-to-br from-blue-50 dark:from-gray-900"
    >
      <div class="container px-4 md:px-6 mx-auto">
        <div class="flex flex-col items-center space-y-8 text-center">
          <div class="space-y-4 max-w-3xl">
            <h1
              class="text-4xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-600 dark:from-blue-400 dark:to-green-400"
            >
              <div class="sm:min-h-[50px] xl:min-h-[64px]">
                Speak Your Way to Fluency
              </div>
            </h1>
            <p
              class="mx-auto max-w-[700px] text-gray-700 md:text-xl dark:text-gray-300"
            >
              The ultimate platform for language learners to practice speaking,
              maintain fluency, and connect with a global community.
            </p>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4 my-8">
            <div
              v-for="(step, index) in journeySteps"
              :key="step.text"
              class="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md journey-step"
              :style="{ animationDelay: `${index * 200}ms` }"
            >
              <component
                :is="step.icon"
                class="h-12 w-12 mb-2 text-blue-500 dark:text-blue-400"
              />
              <span
                class="text-sm font-medium text-gray-600 dark:text-gray-300"
                >{{ step.text }}</span
              >
            </div>
          </div>
          <div class="w-full max-w-sm">
            <!-- todo: add link back to rooms -->
            <router-link
              to="/"
              class="pointer-events-none opacity-50 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-blue-600 text-white hover:bg-blue-700 h-12 px-6 w-full"
            >
              Coming Soon
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-800">
      <div class="container mx-auto px-4 md:px-6">
        <h2
          class="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-8 text-gray-900 dark:text-white"
        >
          Why Language Rooms?
        </h2>
        <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-blue-50 dark:bg-blue-900/30 rounded-xl shadow-lg"
          >
            <Zap class="h-12 w-12 text-blue-500 dark:text-blue-400" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Active Practice
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Engage in real conversations to rapidly improve your speaking
              skills and confidence.
            </p>
          </div>
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-green-50 dark:bg-green-900/30 rounded-xl shadow-lg"
          >
            <Brain class="h-12 w-12 text-green-500 dark:text-green-400" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Maintain Fluency
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Regular speaking sessions help you retain vocabulary and grammar,
              keeping your skills sharp.
            </p>
          </div>
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-yellow-50 dark:bg-yellow-900/30 rounded-xl shadow-lg"
          >
            <Rocket class="h-12 w-12 text-yellow-500 dark:text-yellow-400" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Regain Lost Skills
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Quickly recover your language abilities through immersive
              conversations with native speakers.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- How It Works Section -->
    <section
      class="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-900"
    >
      <div class="container px-4 md:px-6 mx-auto">
        <h2
          class="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-8 text-gray-900 dark:text-white"
        >
          How Language Rooms Work
        </h2>
        <div class="grid gap-6 lg:grid-cols-3">
          <div class="flex flex-col items-center space-y-2 p-6">
            <div
              class="w-12 h-12 rounded-full bg-teal-500 flex items-center justify-center text-white text-2xl font-bold"
            >
              1
            </div>
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Choose Your Room
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Select a language and topic that interests you from our diverse
              range of rooms.
            </p>
          </div>
          <div class="flex flex-col items-center space-y-2 p-6">
            <div
              class="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-white text-2xl font-bold"
            >
              2
            </div>
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Join the Conversation
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Enter a video chat room and start practicing with other learners
              and native speakers.
            </p>
          </div>
          <div class="flex flex-col items-center space-y-2 p-6">
            <div
              class="w-12 h-12 rounded-full bg-purple-500 flex items-center justify-center text-white text-2xl font-bold"
            >
              3
            </div>
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Improve and Grow
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Receive feedback, track your progress, and watch your language
              skills flourish.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- What People Are Saying Section -->
    <!-- <section class="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-800">
      <div class="container px-4 md:px-6 mx-auto">
        <h2
          class="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-8 text-gray-900 dark:text-white"
        >
          What People Are Saying About Language Rooms
        </h2>
        <div class="grid gap-8 md:grid-cols-3">
          <div class="bg-teal-50 dark:bg-teal-900/30 p-6 rounded-xl shadow-lg">
            <p class="text-gray-600 dark:text-gray-300 mb-4 italic">
              "Language Rooms is exactly what I needed for my German practice
              sessions. Engaging with native speakers has been incredibly
              beneficial!"
            </p>
            <p class="text-gray-800 dark:text-gray-100 font-semibold">
              - Maria, Learning German
            </p>
            <p class="text-gray-600 dark:text-gray-300 text-sm mt-2">
              Early Tester
            </p>
          </div>
          <div class="bg-blue-50 dark:bg-blue-900/30 p-6 rounded-xl shadow-lg">
            <p class="text-gray-600 dark:text-gray-300 mb-4 italic">
              "The real-time conversations with native speakers on Language
              Rooms have significantly improved my Mandarin skills. It's a
              game-changer!"
            </p>
            <p class="text-gray-800 dark:text-gray-100 font-semibold">
              - James, Learning Mandarin
            </p>
            <p class="text-gray-600 dark:text-gray-300 text-sm mt-2">
              Early Tester
            </p>
          </div>
          <div
            class="bg-purple-50 dark:bg-purple-900/30 p-6 rounded-xl shadow-lg"
          >
            <p class="text-gray-600 dark:text-gray-300 mb-4 italic">
              "I've tried several methods, but the focus on speaking practice in
              Language Rooms is exactly what I needed for my French fluency."
            </p>
            <p class="text-gray-800 dark:text-gray-100 font-semibold">
              - Alex, Learning French
            </p>
            <p class="text-gray-600 dark:text-gray-300 text-sm mt-2">
              Early Tester
            </p>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Target Group Section -->
    <section
      class="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-900"
    >
      <div class="container px-4 md:px-6 mx-auto">
        <div class="grid gap-6 lg:grid-cols-2 lg:gap-12">
          <div class="flex flex-col justify-center space-y-4">
            <h2
              class="text-3xl font-bold tracking-tighter sm:text-4xl text-gray-900 dark:text-white"
            >
              Perfect for Intermediate Learners and Beyond
            </h2>
            <p class="text-xl text-gray-600 dark:text-gray-400">
              Language Rooms is designed to take your language skills to the
              next level. Whether you're looking to improve, maintain, or regain
              your language abilities, our platform provides the perfect
              environment for your journey.
            </p>
          </div>
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h3
              class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white"
            >
              Who Benefits Most?
            </h3>
            <ul class="space-y-4 text-gray-600 dark:text-gray-400">
              <li class="flex items-start">
                <Zap class="h-6 w-6 mr-2 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                  <strong class="text-gray-900 dark:text-white"
                    >Progressing Learners:</strong
                  >
                  Already speak a little? Accelerate your progress through
                  regular conversation practice with native speakers and fellow
                  learners.
                </div>
              </li>
              <li class="flex items-start">
                <Brain class="h-6 w-6 mr-2 text-green-500 flex-shrink-0 mt-1" />
                <div>
                  <strong class="text-gray-900 dark:text-white"
                    >Rusty Speakers:</strong
                  >
                  Out of practice? Quickly regain your confidence and fluency in
                  a supportive environment with patient conversation partners.
                </div>
              </li>
              <li class="flex items-start">
                <Rocket
                  class="h-6 w-6 mr-2 text-yellow-500 flex-shrink-0 mt-1"
                />
                <div>
                  <strong class="text-gray-900 dark:text-white"
                    >Skill Maintainers:</strong
                  >
                  Don't want to lose your skills? Maintain and improve your
                  language abilities effortlessly through regular, engaging
                  conversations on topics you love.
                </div>
              </li>
              <li class="flex items-start">
                <Users
                  class="h-6 w-6 mr-2 text-purple-500 flex-shrink-0 mt-1"
                />
                <div>
                  <strong class="text-gray-900 dark:text-white"
                    >Cultural Enthusiasts:</strong
                  >
                  Dive deeper into the cultures behind the languages you're
                  learning. Connect with native speakers and gain authentic
                  insights.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Early Access Section -->
    <section
      class="w-full py-12 md:py-24 lg:py-32 bg-teal-600 dark:bg-teal-800"
    >
      <div class="container px-4 md:px-6 mx-auto">
        <div class="flex flex-col items-center space-y-4 text-center">
          <h2
            class="text-3xl font-bold tracking-tighter sm:text-4xl text-white"
          >
            Become a Language Rooms Pioneer
          </h2>
          <p class="max-w-[600px] text-teal-100 md:text-xl">
            We're gradually opening our doors. Join our waitlist for early
            access and be among the first to experience the future of language
            learning!
          </p>
          <form
            class="w-full max-w-sm space-y-2"
            @submit.prevent="submitEarlyAccess"
          >
            <input
              v-model="earlyAccessEmail"
              :disabled="earlyAccessRequested"
              :placeholder="
                earlyAccessRequested
                  ? 'We are excited to welcome you soon!'
                  : 'Enter your email'
              "
              type="email"
              required
              class="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:border-teal-300 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
            />
            <button
              type="submit"
              :disabled="earlyAccessRequested"
              class="w-full px-3 py-2 text-teal-600 bg-white rounded-md hover:bg-teal-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-teal-600"
            >
              {{
                earlyAccessRequested
                  ? 'Early Access Requested!'
                  : 'Request Early Access'
              }}
            </button>
          </form>
          <p class="text-sm text-teal-200 mt-4">
            Once your email is approved, you'll receive full access to Language
            Rooms and can start your speaking journey immediately!
          </p>
        </div>
      </div>
    </section>

    <!-- Roadmap Section -->
    <section
      class="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-900"
    >
      <div class="container px-4 md:px-6 mx-auto">
        <h2
          class="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-8 text-gray-900 dark:text-white"
        >
          Our Exciting Roadmap
        </h2>
        <div class="grid gap-6 md:grid-cols-3">
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg"
          >
            <CheckCircle class="h-12 w-12 text-green-500" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Phase 1: Launch
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Core features and initial language rooms available to early access
              users.
            </p>
          </div>
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg"
          >
            <Users class="h-12 w-12 text-blue-500" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Phase 2: Community Growth
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Expand user base, introduce more languages, and enhance community
              features.
            </p>
          </div>
          <div
            class="flex flex-col items-center space-y-2 p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg"
          >
            <Rocket class="h-12 w-12 text-purple-500" />
            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
              Phase 3: Global Scale
            </h3>
            <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
              Worldwide accessibility, cultural exchange programs, and advanced
              features.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Feedback Section -->
    <section class="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-800">
      <div class="container px-4 md:px-6 mx-auto">
        <div class="flex flex-col items-center space-y-4 text-center">
          <MessageSquare class="h-12 w-12 text-teal-500 dark:text-teal-400" />
          <h2
            class="text-3xl font-bold tracking-tighter sm:text-4xl text-gray-900 dark:text-white"
          >
            Your Voice Matters
          </h2>
          <p class="max-w-[600px] text-gray-500 dark:text-gray-400 md:text-xl">
            We value your input! Look for the "Give Feedback" button at the
            bottom right of every page.
          </p>
          <div
            class="mt-4 p-6 bg-teal-50 dark:bg-teal-900/30 rounded-xl shadow-lg max-w-2xl"
          >
            <h3 class="text-xl font-bold text-gray-900 dark:text-white mb-2">
              Why Your Feedback is Crucial:
            </h3>
            <ul class="text-left text-gray-600 dark:text-gray-300 space-y-2">
              <li>• Shape the future of Language Rooms</li>
              <li>• Suggest new features or improvements</li>
              <li>• Report any issues you encounter</li>
              <li>• Share your success stories</li>
            </ul>
            <p class="mt-4 text-gray-600 dark:text-gray-300">
              Your insights help us create the best possible language learning
              experience. Don't hesitate to reach out – we're listening!
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.journey-step {
  animation: fadeInUp 0.5s ease-out both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
